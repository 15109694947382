//theme
import { theme as footlockerTheme } from 'shared/styles/theme/footlockerTheme';

//data
import footlockerData from 'shared/components/MyAccount/footlockerData';
import JSON_LD_ORGANIZATION_FL from 'shared/constants/JSON_LD_FL/organization';
import JSON_LD_SEARCH_FL from 'shared/constants/JSON_LD_FL/search';

// constants
import { BRAND_NAME_FL as BRAND_NAME } from 'shared/domainConfig/constants';

export const buildConfig = {
  APP_NAME: BRAND_NAME,
  LOGO: {
    MWEB: {
      HEIGHT: 24,
      WIDTH: 118,
      URL: 'https://asset.footlocker.co.in/footlocker-images/flLogo-inverted.svg',
    },
    DWEB: {
      HEIGHT: 28,
      WIDTH: 119,
      URL: 'https://asset.footlocker.co.in/footlocker-images/flLogo.svg',
    },
    FOOTER: {
      HEIGHT: 24,
      WIDTH: 108,
      URL: 'https://asset.footlocker.co.in/footlocker-images/flLogo.svg',
    },
    COUPONS: {
      HEIGHT: 11,
      WIDTH: 54,
      URL: 'https://asset.footlocker.co.in/footlocker-images/flLogo.svg',
      BACKGROUNDURL:
        'https://asset.footlocker.co.in/footlocker-images/light_coupon_background_footlocker.svg',
    },
    OFFERS: {
      HEIGHT: 40,
      WIDTH: 40,
      URL: 'https://asset.footlocker.co.in/footlocker-images/footlocker_offer.svg',
      BACKGROUNDURL:
        'https://asset.footlocker.co.in/footlocker-images/light_coupon_background_footlocker.svg',
    },
    LOGO_ALT_TEXT: BRAND_NAME,
  },
  FAVICON: 'https://asset.footlocker.co.in/footlocker-images/32fevicon.ico',
  PWA_ICONS: {
    ICON_512: 'https://asset.footlocker.co.in/footlocker-images/512.svg',
    ICON_192: 'https://asset.footlocker.co.in/footlocker-images/192.svg',
    ICON_144: 'https://asset.footlocker.co.in/footlocker-images/144.svg',
    ICON_32: 'https://asset.footlocker.co.in/footlocker-images/32.svg',
    ICON_16: 'https://asset.footlocker.co.in/footlocker-images/16.svg',
  },
  ECOM_STRIP_ICONS: {
    FREE_DELIVERY: 'https://asset.footlocker.co.in/footlocker-images/free-delivery.png',
    COD_AVAILABLE: 'https://asset.footlocker.co.in/footlocker-images/cod-available.png',
    RETURN_AND_EXCHANGE: 'https://asset.footlocker.co.in/footlocker-images/7-day-return.png',
    CART_TRUCK: 'https://asset.footlocker.co.in/footlocker-images/truck_fire_footlocker.svg',
  },
  THEME: footlockerTheme,
  MY_ACCOUNT_DATA: footlockerData,
  LOADER_CONFIG: {
    PAGE_LOADER: 'FLoader',
    CHUNK_FALLBACK_CONFIG: 'FLChunkLoader',
  },
  CART_PAYMENT_ICONS: {
    GENUINE_PRODUCTS: 'https://asset.footlocker.co.in/footlocker-images/genuine-products.svg',
    SECURE_PAYMENTS: 'https://asset.footlocker.co.in/footlocker-images/secure-payments.svg',
    EASY_RETURNS: 'https://asset.footlocker.co.in/footlocker-images/easy-returns.svg',
  },
  API_OLD: {
    DOMAIN_PARAM: 'FOOT_LOCKER',
  },
  API_REDIRECTION_PATH: 'fe-api',
  API: {
    DOMAIN_PARAM: 'FOOT_LOCKER',
  },
  DN_STORE: 'fl',
  COLOR: {
    MEGA_MENU: '#000000',
    MEGA_MENU_TEXT: '#FFFFFF',
    MEGA_MENU_BORDER_BOTTOM: '#FFFFFF',
    PROMOTIONAL_BANNER: '#000000',
    PROMOTIONAL_BANNER_TEXT: '#FFFFFF',
    CART_COUNT: {
      BORDER: '#000000',
      TEXT: '#000000',
      BACKGROUND: '#FFFFFF',
    },
    COUPON_CODE_BACKGROUND: '#F7F9FB',
  },
  MY_ACCOUNT_APP_DOWNLOAD: false,
  MEGA_MENU_VERSION: 'V4',
  ABOUT_FOOTER_TEXT: `About ${BRAND_NAME}`,
  GUEST_LOGIN_MESSAGE: `Becoming a ${BRAND_NAME} member comes with easy order tracking, rewards, offers and more.`,
  LOGIN_TOAST_MESSAGE: {
    DWEB: `Dive into your ${BRAND_NAME} account, orders and more`,
    MWEB: `Dive into your ${BRAND_NAME} account, orders and more`,
  },
  DEFAULT_META: {
    title: `The Heart of Sneakers | Sneakers, Apparels & More | ${BRAND_NAME}`,
    description:
      'Latest sneakers, footwear and apparel. Exclusive styles from Nike, adidas, New Balance and more',
    keywords:
      'online shopping sites, online shopping, online shopping websites, sneakers , apparel, shoes, footwear',
  },
  SHARE: {
    PUBLIC_DOMAIN: 'footlocker.co.in',
    TEXT: `Check out this product I found on ${BRAND_NAME}:`,
    CANONICAL_HOMEPAGE_URL: 'https://www.footlocker.co.in',
  },
  COPYRIGHT:
    'The brands "FOOT LOCKER®," "CHAMPS SPORTS®," "LADY FOOT LOCKER®," "KIDS FOOT LOCKER®," "WSS®," and "ATMOS®" are the exclusive trademarks and logos of FOOT LOCKER RETAIL, INC. You may not use such trademarks and logo marks without the prior written consent of FOOT LOCKER RETAIL, INC.',
  JSON_LD_ORG: JSON_LD_ORGANIZATION_FL,
  JSON_LD_SEARCH: JSON_LD_SEARCH_FL,
  LOGIN_IMAGES: {
    STEP_1: 'https://asset.footlocker.co.in/footlocker-images/login-first-screen.svg',
    STEP_2: 'https://asset.footlocker.co.in/footlocker-images/login-otp-screen.svg',
    STEP_3: 'https://asset.footlocker.co.in/footlocker-images/login-otp-screen.svg',
  },
  CART_IMAGES: {
    EMPTY_CART: 'https://asset.footlocker.co.in/footlocker-images/bag-empty.svg',
    UPDATE_CART: 'https://asset.footlocker.co.in/footlocker-images/bag-updated.svg',
  },
  PRODUCT_NOT_FOUND: 'https://asset.footlocker.co.in/footlocker-images/page-not-found.svg',
  EMPTY_WISHLIST_IMAGE: 'https://asset.footlocker.co.in/footlocker-images/wishlist-empty.svg',
  COUPONS_IMAGES: {
    REMOVE_COUPON: 'https://asset.footlocker.co.in/footlocker-images/remove-coupons.svg',
    APPLY_COUPON: 'https://asset.footlocker.co.in/footlocker-images/apply-coupons.svg',
    REMOVE_AND_APPLY: 'https://asset.footlocker.co.in/footlocker-images/remove-and-apply.svg',
    SOMETHING_WENT_WRONG:
      'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/something_went_wrong.svg',
    ERROR: 'https://asset.footlocker.co.in/footlocker-images/no-coupons-available.svg',
    COUPON_BACKGROUND_IMAGE:
      'https://asset.footlocker.co.in/footlocker-images/light_coupon_background_footlocker.svg',
  },
  ALL_BRANDS_DEFAULT_SELECTION: 'All Brands',
  OTP_HELP_CENTER_URL: '/help-center/topic/80',
};
