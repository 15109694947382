export const SORT_FILTERS = [
  {
    label: 'Popularity',
    value: 'popularity',
  },
  {
    label: 'Price Low To High',
    value: 'low-to-high',
  },
  {
    label: 'Price High To Low',
    value: 'high-to-low',
  },
  {
    label: 'New Arrivals',
    value: 'new_arrivals',
  },
  {
    label: 'Bestseller',
    value: 'bestseller',
  },
  {
    label: 'Discount High to Low',
    value: 'discount',
  },
  {
    label: 'Fastest Shipping Time',
    value: 'ships_in',
  },
];

export enum FILTER_TYPES {
  CATEGORY,
  MULTI_SELECT,
  RANGE_FILTER,
  SINGLE_SELECT,
  MORE,
  NONE,
}

export const VALID_FILTERS = [
  FILTER_TYPES.CATEGORY,
  FILTER_TYPES.MULTI_SELECT,
  FILTER_TYPES.SINGLE_SELECT,
  FILTER_TYPES.MORE,
  FILTER_TYPES.RANGE_FILTER,
];

export const CATEGORY_FILTER_KEY = 'category_filter';
export const BRAND_FILTER_KEY = 'brand_filter';
export const COLOR_FILTER_KEY = 'color_filter';
export const PRICE_FILTER_KEY = 'price_filter';
export const MORE_FILTER_TITLE = 'More Filters';

export const FILTER_UI_TYPES = {
  MULT_SLECT: 'multi-select',
  RANGE_FILTER: 'range-filter',
  SINGLE_SELECT: 'single-select',
};
export const FILTER_ACTION_TYPE = 'navigate';
export const FILTER_KEYS = {
  PAGE_SIZE: 'PageSize',
  SORT: 'sort',
};

export const NUMBER_PRODUCTS_PER_PAGE = 36;

export const DEFAULT_FILTERS = {
  [FILTER_KEYS.PAGE_SIZE]: NUMBER_PRODUCTS_PER_PAGE,
  [FILTER_KEYS.SORT]: SORT_FILTERS[0].value,
};

export const ESCAPE_FILTERS = [FILTER_KEYS.PAGE_SIZE, FILTER_KEYS.SORT];
export const EAGER_FILTERS = [CATEGORY_FILTER_KEY, BRAND_FILTER_KEY];

export const ANALYTICS_KEY_JOIN = ':';
export const ANALYTICS_VALUE_JOIN = ',';
export const ANALYTICS_JOIN = '|';
export const ANALYTICS_EMPTY_STRING = 'EMPTY';

export enum ROW_TYPE {
  PLACEHOLDER,
  PAGE_IO,
  PRODUCT,
  FILTER_GUIDE,
  ADPLATFORM_WIDGET,
  QUICK_FILTERS,
}

export const CLASSNAMES = {
  CONTAINER: 'prod-ctr',
  PRODUCT: 'plp-prod',
  WISHLIST: 'wishlist-prod',
};

export const QUICK_FILTERS_CONFIG = 'plpQuickFiltersEnabled';
